import React from "react"
import { useState, useEffect } from "react"
import {
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Typography,
  AppBar,
  Toolbar,
  Link,
  LinearProgress,

} from "@mui/material"
import {
  DarkMode,
  LightMode,
} from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import { useDispatch, useSelector } from "react-redux"
import { setMode, setSide } from "state"
import { useNavigate, useLocation } from "react-router-dom"
import './styles.scss'
import SearchBar from "component/Navbar/SearchBar"
import logo from 'assets/logo.png'
function NavBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [displayMenuIcon, setDisplayMenuIcon] = useState(false)
  const side = useSelector((state) => state.auth.side)
  // const user = useSelector((state) => state.auth.user)
  const isPc = useMediaQuery("(min-width: 1000px)")
  const theme = useTheme()
  const [progress, setProgress] = useState(0);
  const location = useLocation(); // Add the useLocation hook


  const logoColor = theme.palette.primary.dark
  const logoColorHover = theme.palette.primary.light

  const alt = theme.palette.background.alt
  const textColor = theme.palette.text.mediumMain
  const textColorHover = theme.palette.text.mediumLight
  // const name = user.firstName + " " + user.lastName
  const handleMenuItemClick = (path) => {
    navigate(path);
    setDisplayMenuIcon(false);
  };
  const toggleSidebar = () => {
    //dispatch(setSide())

      // Scroll to the top of the page
      window.scrollTo({
        bottom: 0,
        behavior: 'smooth', // Optional: Adds smooth scrolling effect
      });
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const contentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / contentHeight) * 100;
      setProgress(scrollPercentage);
    };

    // Add event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 3%', backgroundColor: alt }}>
        <Typography
          variant="h6"
          fontWeight="bold"
          fontSize={{ xs: '2rem', md: 'clamp(1.5rem, 2vw, 2rem)' }}
          color={logoColor}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: logoColorHover,
            },
          }}
          onClick={() => navigate('/home')}
        >
          _chacochi_
        </Typography>
        {/* <img src={logo} alt="logo" width={100} height={50} /> */}
        {isPc ? (
          <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
            <div
              style={{
                padding: '10px',
                margin: '0 auto',
                cursor: 'pointer',
                justifyContent: 'center',
                display: 'flex',
                /* Add your styles for the replacement element here */
              }}
              onClick={() => dispatch(setMode())}
            >
              {theme.palette.mode === 'dark' ? (
                <LightMode sx={{ color: textColor, '&:hover': {color: textColorHover}, fontSize: '25px' }} />
              ) : (
                <DarkMode sx={{ fontSize: '25px',color: textColor, '&:hover': {color: textColorHover} }} />
              )}
            </div>
            <SearchBar />
            <Typography className='linkNav' variant="h5" sx={{
              color: textColor,
              cursor: 'pointer', ml: '10px',
              '&:hover': {
                color: textColorHover,
              },
            }} onClick={() => handleMenuItemClick('/home')}>Home</Typography>
            <Typography className='linkNav' variant="h5" sx={{
              cursor: 'pointer', '&:hover': {
                color: textColorHover,
              },color: textColor
            }} onClick={() => handleMenuItemClick('/blogs')}>Blog</Typography>
            <Typography className='linkNav' variant="h5" sx={{
              cursor: 'pointer',color:textColor, '&:hover': { color: textColorHover }
            }} onClick={() => handleMenuItemClick('/contact')}>Contact</Typography>

            <Link href="#aboutus" color={textColor} underline="none">
              <Typography
                variant="h5"
                sx={{ cursor: 'pointer', '&:hover': { color: textColorHover } }}
                onClick={toggleSidebar}
              >
                About us
              </Typography>
            </Link>
          </div>
        ) : (

          <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
            <SearchBar />
            <MenuIcon sx={{
              '&:hover': {
                color: textColorHover
              },
              cursor: 'pointer', color: textColor,
            }} onClick={() => setDisplayMenuIcon(!displayMenuIcon)}
              fontSize="25px" />
          </div>
        )}
        {displayMenuIcon && (

          <Menu
            anchorEl={document.body}
            open={displayMenuIcon}
            onClose={() => setDisplayMenuIcon(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            justifyContent="center"
          >
            <MenuItem sx={{ justifyContent: "center" }} >
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',
                  textAlign: 'center',
                  justifyContent: "center",

                  /* Add your styles for the replacement element here */
                }}
                onClick={() => dispatch(setMode())}
              >
                {theme.palette.mode === 'dark' ? (
                  <LightMode sx={{ color: textColor, '&:hover': {color: textColorHover}, fontSize: '25px' }} />
                ) : (
                  <DarkMode sx={{color: textColor, '&:hover': {color: textColorHover}, fontSize: '25px' }} />
                )}
              </div>
            </MenuItem>
            <MenuItem sx={{ justifyContent: "center" }} onClick={() => handleMenuItemClick('/home')}>
              <Typography className='linkNav' variant="h5" sx={{
                cursor: 'pointer', ml: '10px', color: textColor, '&:hover': {
                  color: textColorHover,
                },
              }} onClick={() => handleMenuItemClick('/dashboard')}>Home</Typography>
            </MenuItem>
            <MenuItem sx={{ justifyContent: "center" }} onClick={() => handleMenuItemClick('/blogs')}>
              <Typography className='linkNav' variant="h5" sx={{
                cursor: 'pointer', color: textColor, '&:hover': {
                  color: textColorHover
                }
              }} onClick={() => handleMenuItemClick('/blogs')}>Blog</Typography>
            </MenuItem>
            <MenuItem sx={{ justifyContent: "center" }} onClick={() => handleMenuItemClick('/contact')}>
              <Typography className='linkNav' variant="h5" sx={{
                cursor: 'pointer', color: textColor, '&:hover': {
                  color: textColorHover
                }
              }} onClick={() => handleMenuItemClick('/contact')}>Contact</Typography>
            </MenuItem>
            <MenuItem sx={{ justifyContent: "center" }} >
              <Link href="#aboutus" color={textColor} underline="none">
                <Typography
                  variant="h5"
                  sx={{ cursor: 'pointer', '&:hover': { color: textColorHover } }}
                  onClick={toggleSidebar}
                >
                  About us
                </Typography>
              </Link>
            </MenuItem>
          </Menu>
        )}
      </Toolbar>
      {location.pathname.startsWith('/blogs/') && (
      <div>
        <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
        </div>
        )}
    </AppBar>

  )
}

export default NavBar