import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { setLike } from 'state';

const LikeButton = ({ blogId }) => {
    const dispatch = useDispatch();
    const likedBlogs = useSelector((state) => state.auth.like);
    const handleOnClick = (event) => {
        event.preventDefault();
        const isLiked = likedBlogs.includes(blogId);
        console.log('isLiked', isLiked);
        if (isLiked) {
            dispatch(setLike(likedBlogs.filter((id) => id !== blogId)));
        } else {
            dispatch(setLike([...likedBlogs, blogId]));
        }
    };

    const IconComponent = likedBlogs.includes(blogId) ? (
        <FavoriteIcon color="primary" fontSize="small" />
    ) : (
        <FavoriteBorderIcon fontSize="small" style={{ color: 'blue' }} />
    );

    return (
        <CardActions style={{ justifyContent: 'center' }}>
            <div style={{ display: 'flex', gap: '50px' }}>
                <Button onClick={handleOnClick}>
                    {IconComponent}
                </Button>
            </div>
        </CardActions>
    );
};

export default LikeButton;
