import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CustomSkeleton = () => {
  return (
    <Stack style={{marginTop: '30px', marginBottom: '30px',}}>
      <Skeleton variant="rectangular" width='240px' height='230px' style={{borderRadius: '10px', }}/>
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} style={{borderRadius: '10px', }}/>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{borderRadius: '10px', }}/>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{borderRadius: '10px', }}/>
    </Stack>
  );
}

export default CustomSkeleton;
