import React, {useState} from 'react'
import './style.scss'
import contactApi from 'api/contactApi'
import Swal from 'sweetalert2'
const ContactPage = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const response = await contactApi.postContact(name, email, message)
            console.log(response.message)
            Swal.fire({
                position: "center",
                icon: "success",
                title: response.message,
                showConfirmButton: false,
                timer: 1500
              });
            setName('')
            setEmail('')
            setMessage('')
        } catch (error) {
            console.error(error)
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Something went wrong",
                showConfirmButton: false,
                timer: 1500
              });
        }
      }
  return (
        <section className="formcarry-container">
        <form action="#" method="POST" enctype="multipart/form-data" onSubmit={handleSubmit}>
            
            <div className="formcarry-block">
            <label for="fc-generated-1-name">Tên của bạn</label>
            <input type="text" name="name" id="fc-generated-1-name" placeholder="Cha Co Chi" value={name} onChange={(e)=> setName(e.target.value)} />
            </div>
            
            <div className="formcarry-block">
            <label for="fc-generated-1-email">Email của bạn</label>
            <input type="email" name="email" id="fc-generated-1-email" placeholder="contact@chacochi.com" value={email} onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            
            <div class="formcarry-block">
            <label for="fc-generated-1-message">Lời nhắn đến chúng mình</label>
            <textarea name="message" id="fc-generated-1-message" placeholder="Chúc cậu một ngày tốt lành <3" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
            </div>
            
            <div className="formcarry-block">  
            <button type="submit">Gửi</button>
            </div>
        
        </form>
        </section>

  )
}

export default ContactPage