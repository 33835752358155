// color design tokens export
export const colorTokens = {
  grey: {
    0: "#ffffff",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#030337",
    1100: "#000000",
  },
  primary: {
    50: "#99D3FF",        // chacochi text
    100: "#43B0F1",
    200: "#0E97FF",
    300: "#429EBD",
    400: "#1C98ED",
    500: "#057DCD",
    600: "#416OE1",
    700: "#4682C4",
    800: "#025492",       // Title
    900: "#000060",
  },
  secondary: {
    50: "#B6D3FF",
    100: "#D6E8EE",
    200: "#97CADB",
    300: "#99deeb",
    400: "#429EBD",
    500: "#8ca1c8",
    600: "#022BFF",
    700: "#88A5FF",
    800: "#A2A0FF",
    900: "#8857FF",
  },
  
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            dark: colorTokens.secondary[400],
            main: colorTokens.secondary[200],
            light: colorTokens.secondary[300],
          },
          neutral: {
            dark: colorTokens.grey[100],
            main: colorTokens.grey[200],
            mediumMain: colorTokens.grey[300],
            medium: colorTokens.grey[400],
            light: colorTokens.grey[700],
          },
          background: {
            default: colorTokens.grey[1000],
            alt: colorTokens.grey[800],
            contact: colorTokens.primary[900],
            footer: colorTokens.grey[1100],
          },
          text: {
            main: colorTokens.primary[600],
            light: colorTokens.primary[900],
            mediumMain: colorTokens.secondary[400],
            mediumLight: colorTokens.secondary[200],
          },
          search: {
            main: colorTokens.primary[200],
            light: colorTokens.primary[100],
          },
          icon:{
            main: colorTokens.primary[700],
            light: colorTokens.primary[700],
          }

        }
        : {
          // palette values for light mode
          primary: {
            dark: colorTokens.primary[700],
            main: colorTokens.primary[800],
            light: colorTokens.primary[800],
          },
          neutral: {
            dark: colorTokens.grey[700],
            main: colorTokens.grey[500],
            mediumMain: colorTokens.grey[400],
            medium: colorTokens.grey[300],
            light: colorTokens.grey[50],
          },
          background: {
            default: colorTokens.grey[10],
            alt: colorTokens.grey[0],
            contact: colorTokens.primary[100],
          },
          text: {
            main: colorTokens.primary[600],
            light: colorTokens.primary[900],
            mediumMain: colorTokens.primary[400],
            mediumLight: colorTokens.primary[100],
          },
          search: {
            main: colorTokens.primary[100],
            light: colorTokens.primary[200],
          },
          icon:{
            main: colorTokens.primary[900],
            light: colorTokens.primary[200],
          }
        }),
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontFamily: ["Lobster", "sans-serif"].join(","),
        fontSize: 48,
      },
      h2: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

