import React, { useState } from 'react';
import { Box, useMediaQuery, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import Navbar from 'component/Navbar';
import Sidebar from 'component/Sidebar';
import Footer from 'component/Footer';
//import Sidebar from 'component/Sidebar/sidebar';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';



const Layout = () => {
  //const side = useSelector((state) => state.auth.side);
  const isPc = useMediaQuery('(min-width: 1000px)');
  return (
    <>
    <Box className="layout" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, padding: '64px 0 0' }}>
        <Grid container spacing={2}>
          {/* Main content */}
          <Grid item xs={12} md={ 12}>
            <Outlet />
          </Grid>
          {/* Sidebar */}
          {/* {side && (
            <Grid item xs={12} md={4}>
              <Paper elevation={0} sx={{ position: 'sticky', top: 15 }}>
                <Sidebar />
              </Paper>
            </Grid>
          )} */}
        </Grid>
      </Box>
      <Footer />
    </Box>
    </>
  );
};

export default Layout;





