import authReducer from 'state'

import { configureStore } from '@reduxjs/toolkit'
import {
    
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import {blogApi} from 'services/blog.service'
import { setupListeners } from '@reduxjs/toolkit/query'
import { composeWithDevTools } from '@redux-devtools/extension'

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
}

const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    [blogApi.reducerPath]: blogApi.reducer,
})

// const persistedReducer = persistReducer(persistConfig, authReducer)
export const store = configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(blogApi.middleware),
    //devTools: false,
})
setupListeners(store.dispatch)
