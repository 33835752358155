import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mode: 'light',
    side: false,
    user: null,
    token: null,
    posts: [],
    like: [],
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? 'dark' : 'light'
        },
        setLogin: (state, action) => {
            state.user = action.payload.user
            state.token = action.payload.token
        },
        setLogout: (state) => {
            state.user = null
            state.token = null
        },
        setPosts: (state, action) => {
            state.posts = action.payload
        },
        setSide(state) {
            state.side = !state.side
        },
        setLike(state, action){
            state.like = action.payload
        }
    }
})

export const { setMode, setLogin, setLogout, setPosts, setSide, setLike } = authSlice.actions
export default authSlice.reducer
