import React from 'react'
import { useTheme, useMediaQuery, Box, Typography } from '@mui/material'
import Form from './Form'

const LoginPage = () => {
    const theme = useTheme();
    const isPC = useMediaQuery("(min-width:1024px)")

  return (
    <Box>
        <Box 
        width = '100%'
        backgroundColor = {theme.palette.background.alt}
        p = '1rem 6%'
        >
            <Typography
            variant = 'h4'
            fontWeight = '500'
            classname = 'text-center'
            align = 'center'
            >
                Login
            </Typography>
        </Box>
        <Box
        width={isPC ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Welcome to Chacochi
        </Typography>
        <Form />
      </Box>
    </Box>
  )
}

export default LoginPage