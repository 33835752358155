import React, { Fragment, useEffect, useState } from 'react'
import Editor from 'component/Editor'
import { Navigate, useParams } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css'
import './styles.scss'

import blogApi from 'api/blogApi'
import seed from 'assets/seed.jpg'
import {
    useAddBlogMutation,
    useGetBlogQuery,
    useUpdateBlogMutation,
} from 'services/blog.service'

const initialBlog = {
    title: '',
    summary: '',
    body: '',
    image: '',
    read_time: '',
    created_at: '',
    category: '',
}
const CreateBlog = () => {
    const [formData, setFormData] = useState(initialBlog)
    const [addBlog, addblogresult] = useAddBlogMutation()
    const [updateBlog, updateBlogResult] = useUpdateBlogMutation()
    const { id } = useParams()
    console.log(id)
    const { data, isLoading, isFetching } = useGetBlogQuery(id, { skip: !id })
    console.log(data, isLoading, isFetching)
    useEffect(() => {
        if (data) {
            setFormData(data)
        }
    }, [data])

    // const [title, setTitle] = useState('')
    // const [summary, setSummary] = useState('')
    // const [body, setBody] = useState('')
    // const [file, setFile] = useState('')
    // const [redirect, setRedirect] = useState(false)
    // const [readTime, setReadTime] = useState('')
    // const [createAt, setCreateAt] = useState('')
    // const [category, setCategory] = useState('')
    const createNewPost = async (ev) => {
        ev.preventDefault()
        if (id) {
            const res = await updateBlog({
                id: id,
                body: formData,
            }).unwrap()
            console.log('update: ', res)
            window.location.href = `/blogs/${id}`
        } else {
            await addBlog(formData)
            setFormData(initialBlog)
        }
        // const formData = new FormData()
        // formData.set('title', title)
        // formData.set('summary', summary)
        // formData.set('body', body)
        // formData.set('category', category)
        // formData.set('image', file)
        // formData.set('read_time', readTime)
        // formData.set('created_at', createAt)
        // ev.preventDefault()

        // const response = await blogApi.addBlog(formData)
        // console.log(response)
        // if (response) {
        //   alert('Create blog success')
        // }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useEffect(() => {
    //   const fetchPost = async () => {
    //     const response = await blogApi.getBlogs()
    //     console.log(response)
    //   }
    //   fetchPost()
    // }, [])

    return (
        <div className='createBlog'>
            <form onSubmit={createNewPost}>
                <input
                    className='title'
                    type='title'
                    placeholder={'Title'}
                    value={formData.title}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, title: ev.target.value }))
                    }
                />
                <input
                    className='title'
                    type='summary'
                    placeholder={'Summary'}
                    value={formData.summary}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, summary: ev.target.value }))
                    }
                />
                <input
                    className='title'
                    type='summary'
                    placeholder={'Category'}
                    value={formData.category}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, category: ev.target.value }))
                    }
                />
                <input
                    className='title'
                    type='body'
                    placeholder={'Read time'}
                    value={formData.read_time}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, read_time: ev.target.value }))
                    }
                />
                <input
                    className='createAt'
                    type='text'
                    placeholder={'create at'}
                    value={formData.created_at}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, created_at: ev.target.value }))
                    }
                />
                <input
                    className='Like'
                    type='img'
                    placeholder={'img'}
                    value={formData.image}
                    onChange={(ev) =>
                        setFormData((prev) => ({ ...prev, image: ev.target.value }))
                    }
                />

                {/* <input
        className='title'
        type="file"
        onChange={ev => setFormData()> */}
                <Editor
                    value={formData.body}
                    onChange={(value) =>
                        setFormData((prev) => ({ ...prev, body: value }))
                    }
                />
                {/* <img src="https://drive.google.com/thumbnail?id=1P0wgk_ygcnW1AqxLvPm-3XpAqBsZqrHv" alt="a"  style={{ width: "100%", }}/>
      <img src={seed} alt="a"  style={{ width: "100%" }}/>
      <a href="https://imgur.com/TCDSPUD"><img src="https://i.imgur.com/TCDSPUD.jpg" title="source: imgur.com" /></a>
      <a href="https://imgur.com/TCDSPUD"><img src="https://imgur.com/dnJjPOh.jpg" title="source: imgur.com" /></a> */}
                {!id && (
                    <button type='submit' style={{ marginTop: '5px' }}>
                        Create post
                    </button>
                )}
                {id && (
                    <Fragment>
                        <button type='submit' style={{ marginTop: '5px' }}>
                            Update post
                        </button>
                        <button style={{ marginTop: '5px' }}>Cancel</button>
                    </Fragment>
                )}
            </form>
        </div>
    )
}

export default CreateBlog
