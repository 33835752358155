import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';

const ShareButton = (blogId) => {
    console.log(blogId)
    const handleShareClick = (event) => {
        event.preventDefault();
        const shareUrl = `https://frontendchacochi.onrender.com/blogs/${blogId.blogId}`;
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
        };
    return (
        <Button onClick={handleShareClick}>
            <ShareIcon fontSize="small" style={{ color: 'blue' }} />
        </Button>
    );
};
export default ShareButton;