import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate } from 'react-router-dom'
export default function SearchDialog({ playholder, title, content, button }) {
    const [open, setOpen] = React.useState(false)
    const [keyword, setKeyword] = React.useState('')
    const navigate = useNavigate()
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = () => {
        navigate(`/blogs?keyword=${keyword}`)
    }

    return (
        <React.Fragment>
            <Button variant='' onClick={handleClickOpen}>
                {}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault()
                        const formData = new FormData(event.currentTarget)
                        const formJson = Object.fromEntries(formData.entries())
                        const email = formJson.email
                        console.log(email)
                        handleClose()
                    },
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin='dense'
                        id='name'
                        name='email'
                        label={playholder}
                        fullWidth
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Hủy</Button>
                    <Button type='submit' onClick={handleSubmit}>
                        {button}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
