import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import SearchDialog from 'component/Dialog';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.search.main,
  '&:hover': {
    backgroundColor: theme.palette.search.light,
  },
  marginLeft: 0,
  marginTop: '10px',
  marginBottom: '10px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchBar = () => {
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isPc = useMediaQuery('(min-width: 1000px)');
  const [showSearchInput, setShowSearchInput] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate(`/blogs?keyword=${keyword}`);
    }
  };

  const handleIconClick = () => {
    setShowSearchInput(true);
  };

  return (
    <Search theme={theme}>


        <>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          {/* <StyledInputBase
            placeholder="bài viết..."
            inputProps={{ 'aria-label': 'search' }}
            value={keyword}
            onChange={(e) => {setKeyword(e.target.value)}}
            onKeyDown={handleKeyDown}
          /> */}
      <SearchDialog playholder="bài viết" content="nhập vào đây tên của bài viết" title="Tìm kiếm" button="Gửi"/>
        </>

    </Search>

  );
};

export default SearchBar;
