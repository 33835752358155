import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image1 from 'page/listBlogPage/image.webp'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import { Category } from '@mui/icons-material';
import LikeButton from './LikeButton.js';
import ShareButton from './ShareButton.js';
import { useSelector, useDispatch } from 'react-redux';

class Blog extends React.Component {

    render() {
        console.log(this.props.blogData);
        const { id, title, summary, image, read_time, created_at, category } = this.props.blogData;
        return (
            <Link to={{ pathname: `/blogs/${id}`, state: { blog: this.props.blogData } }} style={{ textDecoration: 'none' }}>
                <Card style={{ marginTop: '30px', marginBottom: '30px', borderRadius: '10px', }}>
                    <CardMedia component="img" height="100%" image={image} alt="green iguana" style={{ height: '200px' }} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" style={{
                            height: '40px',
                            maxHeight: '40px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            '-webkit-line-clamp': 2,
                            '-webkit-box-orient': 'vertical'
                        }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{
                            height: '50px',
                            maxHeight: '50px',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            '-webkit-line-clamp': 3,
                            '-webkit-box-orient': 'vertical'
                        }}>
                            {summary.length > 69 ? `${summary.substring(0, 69)}...` : summary}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
                            {category} trong
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
                            {read_time}',
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
                            {new Date(created_at).toLocaleDateString('en-GB')}
                        </Typography>
                    </CardActions>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <div style={{ display: 'flex', gap: '50px' }}>
                            <LikeButton blogId={id} />
                            <ShareButton blogId={id} />
                        </div>
                    </CardActions>
                </Card>
            </Link>
        );
    }
}
export default Blog;