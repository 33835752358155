import React from 'react'
import blogApi from 'api/blogApi'
import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme, useMediaQuery, Typography, LinearProgress } from '@mui/material'
import BasicSpeedDial from './SpeedDial'
import './style.scss'
import MetaTags from 'react-meta-tags'
import { Helmet } from 'react-helmet-async'
import { useGetBlogQuery, useDeleteBlogMutation } from 'services/blog.service'

import ShareButton from 'page/listBlogPage/ShareButton'
import Skeleton from '@mui/material/Skeleton'
const ContentBlog = () => {
    const { id } = useParams()

    const [content, setContent] = useState('')
    const mode = useSelector((state) => state.auth.mode)
    const [progress, setProgress] = React.useState(0)
    const isPC = useMediaQuery('(min-width:1024px)')
    const { data, isLoading, isFetching } = useGetBlogQuery(id, { skip: !id })
    const [deleteBlog, deleteBlogResult] = useDeleteBlogMutation()
    console.log(data, isLoading, isFetching)

    const progressRef = useRef(progress)
    progressRef.current = progress
    function convertEscapedHtml(htmlString) {
        htmlString = htmlString.replace(/&lt;/g, '<')
        htmlString = htmlString.replace(/&gt;/g, '>')
        htmlString = htmlString.replace(/&amp;/g, '&')
        htmlString = htmlString.replace(/<p>(<img .*?\/>)<\/p>/g, '$1')
        return htmlString
    }

    // useEffect(() => {
    //     // const fetchBlog = async () => {
    //     //   const response = await blogApi.getBlog(id)
    //     //   setContent(convertEscapedHtml(response.body))
    //     //   setBlog(response)
    //     // }
    //     // fetchBlog()

    //     if (data) {
    //         console.log('data', data)
    //         setContent(convertEscapedHtml(data.body))
    //     }

    //     if (mode === 'dark') {
    //         console.log('dark')
    //         document.body.classList.add('dark-mode')
    //     } else {
    //         document.body.classList.remove('dark-mode')
    //     }
    // }, [data, mode])

    useEffect(() => {
        if (data) {
            console.log('data', data)
            setContent(convertEscapedHtml(data.body))
        }
    }, [data])

    useEffect(() => {
        if (mode === 'dark') {
            console.log('dark')
            document.body.classList.add('dark-mode')
        } else {
            document.body.classList.remove('dark-mode')
        }
    }, [mode])
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop
            const contentHeight =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight
            const scrollPercentage = (scrollTop / contentHeight) * 100
            setProgress(scrollPercentage)
        }

        // Add event listener when component mounts
        window.addEventListener('scroll', handleScroll)

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const img = data?.image
    const url = window.location.href
    console.log('url', url)
    const handleDelete = async () => {
        await deleteBlog(id)
        window.location.href = '/blogs'
    }

    return (
        <>
            <div className='singlePost'>
                <div
                    className='blog-read-time-bar -active'
                    data-read-time-bar='blog-page-read-time'
                >
                    <LinearProgress variant='determinate' value={progress} />
                </div>
                <div className='singlePost__wrapper'>
                    {isFetching ? (
                        <Skeleton
                            className='singlePost__wall'
                            sx={{ bgcolor: 'grey.900' }}
                            variant='rectangular'
                        />
                    ) : (
                        <img className='singlePost__wall' src={img} alt={data?.title} />
                    )}
                    {/* <p className="singlePost__date">{blog.created_at}</p> */}
                </div>
                <div className='singlePost__info'>
                    <Typography variant='h5' color='primary'>
                        {data?.category} trong {data?.read_time} phút
                    </Typography>
                    <Typography variant='h5' color='primary'>
                        {new Date(data?.created_at).toLocaleDateString('en-GB')}
                    </Typography>
                </div>

                <div className='singlePost__content'>
                    {isFetching ? (
                        <Typography variant='h1'>
                            <Skeleton />
                        </Typography>
                    ) : (
                        <Typography
                            variant='h1'
                            color='primary'
                            className='singlePost__title'
                        >
                            {data?.title}
                        </Typography>
                    )}
                    <Typography variant='h4' color='neutral' className='singlePost__sum'>
                        {data?.summary}
                    </Typography>
                    {isFetching ? (
                        <Typography
                            className='singlePost__desc'
                            conponent='div'
                            variant='body1'
                        >
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Typography>
                    ) : (
                        <div
                            className='singlePost__desc'
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )}
                </div>
                <BasicSpeedDial className='basicSpeedDial' />
                <div className='singlePost__share'>
                    <ShareButton blogId={id} />
                </div>
                {/* <div>
                  <button className='singlePost__edit'>
                      <a href={`/create-blog/${id}`}>Edit</a>
                  </button>
              </div>
              <div>
                  <button className='singlePost__delete' onClick={()=> handleDelete()}>
                      Delete
                  </button>
              </div> */}
            </div>
            <Helmet>
                <title>{data?.title}</title>
                <meta property='og:url' content={url} />
                <meta property='og:type' content='website' />
                <meta property='og:title' content={data?.title} />
                <meta property='og:description' content={data?.summary} />
                <meta property='og:image' content={img} />
            </Helmet>
        </>
    )
}

export default ContentBlog
