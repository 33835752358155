import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    tagTypes: ['Blog'],
    
    endpoints: (build) => ({
        getBlogs: build.query({
            query: () => 'blogs',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'Blog', id })),
                          { type: 'Blog', id: 'LIST' },
                      ]
                    : [{ type: 'Blog', id: 'LIST' }],
        }),
        addBlog: build.mutation({
            query(body) {
                return {
                    url: `blogs`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: [{ type: 'Blog', id: 'LIST' }],
        }),
        getBlog: build.query({
            query: (id) => `blogs/${id}`,
            providesTags: (result, error, id) => [{ type: 'Blog', id }],
        }),
        updateBlog: build.mutation({
            query: (data) => ({
                url: `blogs/${data.id}`,
                method: 'PUT',
                body: data.body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Blog', id }],
        }),
        deleteBlog: build.mutation({
            query: (id) => ({
                url: `blogs/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:(result, error, id) => [{ type: 'Blog', id }],
        }),
    }),
})

export const {
    useGetBlogsQuery,
    useAddBlogMutation,
    useGetBlogQuery,
    useUpdateBlogMutation,
    useDeleteBlogMutation,
} = blogApi
