import React from 'react';
import HomePage from 'page/homePage';
import LoginPage from 'page/loginPage';
import ListBlog from 'page/listBlogPage';
import ContentBlog from 'page/contentBlogPage';
import CreateBlog from 'page/createBlog';
import Layout from 'component/Layout';
import ContactPage from 'page/contactPage';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Navigate, Route } from 'react-router-dom';
import { themeSettings } from 'theme';
import 'app.scss'; 
function App() {
  const mode = useSelector((state) => state.auth.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  const isAuth = Boolean(useSelector((state) => state.user))

  return (
    <div className="App">
      <BrowserRouter basename='/'>
        <ThemeProvider theme = {theme}>
          <CssBaseline />
          <Routes>
            <Route
                  path="/"
                  element={<Navigate to="/home" replace />}
                />
            <Route path='/home' element = {<HomePage/>} />
            <Route element = {<Layout />} >
            {/* <Route path='/home' element = {isAuth ? <HomePage /> : <Navigate to='/' />} />
            <Route path='/blog' element = {isAuth ? <HomePage /> : <Navigate to='/' />} /> */}
            <Route path='/login' element = {<LoginPage/>} />
            <Route path='/blogs' element = {<ListBlog/>} />
            <Route path='/blogs/:id' element = {<ContentBlog/>} />
            <Route path='/create-blog' element = {<CreateBlog/>} />
            <Route path='/create-blog/:id' element = {<CreateBlog/>} />
            <Route path='/contact' element = {<ContactPage/>} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
