import React, { useEffect } from 'react';
import background from 'assets/background2.mp4';
import backgroundMobile from 'assets/background_mobile.mp4';
import './style.scss'; // Include your updated CSS (below)
import { useNavigate } from "react-router-dom"
import { useState } from "react";
import gsap from 'gsap';
import { useMediaQuery } from '@mui/material';

const HomePage = () => {
  const navigate = useNavigate();
  const button = document.getElementById('fixedButton');
  const isPC = useMediaQuery('(min-width:1000px)');

  gsap.to(button, {
    duration: 5,
    ease: 'power2.inOut',
    x: '100px',
    y: '100px',
    rotation: 360,
    repeat: 10,
  });
  return (
    <>
    { isPC ? (<div className="containerApp">
      <video className="videoTag" autoPlay loop muted>
        <source src={background} type="video/mp4" />
      </video>
      <button
        className="custom-btn btn-6"
        id="fixedButton"
        onClick={() => navigate('/blogs')}
      >
        <span>Dạo một vòng</span>
      </button>
    </div>) : (<div className="containerApp">
      <video className="videoTag" autoPlay loop muted>
        <source src={backgroundMobile} type="video/mp4" />
      </video>
          <button
            className="custom-btn btn-6"
            id="fixedButton"
            onClick={() => navigate('/blogs')}
          >
            <span>Dạo một vòng</span>
          </button>
        </div> )}
      </>
  );
};

export default HomePage;