import React, { useState, useEffect } from 'react';
import Blog from './Blog.js';
import './Style.scss';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import blogApi from 'api/blogApi.js';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CustomSkeleton from './Skeletion.js';
import { useSelector } from 'react-redux';
import { useGetBlogsQuery } from 'services/blog.service.js';
const ListBlog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(window.innerWidth > 1024 ? 6 : 4);
  const [searchKeyword, setSearchKeyword] = useState('');
  const theme = useTheme();
  const location = useLocation();
  const likes = useSelector((state) => state.auth.like);
  const [totalPages, setTotalPages] = useState(0);


  const backgroundColor = theme.palette.background.default;
  const { data, isLoading, isFetching } = useGetBlogsQuery()

  console.log(data, isLoading, isFetching)

  useEffect(() => {
    const handleResize = () => {
      setPostsPerPage(window.innerWidth > 1024 ? 6 : 4);
    };
    window.addEventListener('resize', handleResize);
    const params = new URLSearchParams(location.search)
    const keyword = params.get('keyword')
    setSearchKeyword(keyword)
    
    if (data) {
        //current data

        let filteredBlogs = data
        if (searchKeyword) {
            filteredBlogs = data.filter((blog) =>
                blog.title.toLowerCase().includes(searchKeyword.toLowerCase()),
            )
        }
        const totalPages = Math.ceil(filteredBlogs.length / postsPerPage)
        setTotalPages(totalPages)
        // set data current page
        const indexOfLastPost = currentPage * postsPerPage
        const indexOfFirstPost = indexOfLastPost - postsPerPage
        const currentPosts = filteredBlogs.slice(indexOfFirstPost, indexOfLastPost)
        console.log(currentPosts)
        setCurrentPosts(currentPosts)

    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, [location.search, data, searchKeyword, postsPerPage, currentPage]);


  const handlePageChange = (event, newPage) => {
      console.log(newPage)
      setCurrentPage(newPage)
  }
    return (
      <>
      {isFetching && ( 
      <div className="blog-page" style={{ backgroundColor: backgroundColor, }}>
        <div className="content" style={{ alignItems: 'center' }}>
          <div className="blog-page__blog-list">
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
          </div>
        </div>
      </div>
      )}

      { !isFetching && data?.length > 0 && (
      <div className="blog-page" style={{ backgroundColor: backgroundColor }}>
        <div className="blog-page__content" style={{ alignItems: 'center' }}>
          <div className="blog-page__blog-list">
            {currentPosts?.map((blog) => (
              <div key={blog.id} className="blog-page__blog-item">
                <Link to={`/blogs/${blog.id}`} style={{ textDecoration: 'none' }}>
                  <Blog blogData={blog} />
                </Link>
              </div>
            ))}
          </div>
          <div className="blog-page__pagination">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
              />
            </Stack>
          </div>
        </div>
      </div>

  )}
   { !isFetching && data?.length === 0 && (
      <div className="blog-page">
        <div className="content" style={{ alignItems: 'center' }}>
          <h1>Không tìm thấy kết quả!</h1>
        </div>
      </div>
    )}
  </>)
};

export default ListBlog;