import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { ArrowUpward } from '@mui/icons-material';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Tooltip from '@mui/material/Tooltip';

export default function BasicSpeedDial() {
    const scrollToTop = () => {
        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: Adds smooth scrolling effect
        });
      };
    
  return (
    <Box sx={{ height: 320, position: 'fixed', bottom:'-230px', right:'16px'}}>
      <Tooltip title="Go to Header" arrow>
        <SpeedDial
          ariaLabel="Scroll to Top"
          icon={<ArrowUpward />}
          onClick={scrollToTop}
        />
      </Tooltip>
    </Box>
  );
}
