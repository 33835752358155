import React, { useEffect } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    ButtonBase,
} from '@mui/material'
import { IconButton } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import GitHubIcon from '@mui/icons-material/GitHub'
import TwitterIcon from '@mui/icons-material/GitHub'
import InstagramIcon from '@mui/icons-material/Mail'
import PinterestIcon from '@mui/icons-material/Pinterest'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import Logo from 'assets/logo.png'
import LogoDark from 'assets/logoDark.png'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { GitHub } from '@mui/icons-material';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
})
const Footer = () => {
    const mode = useSelector((state) => state.auth.mode)
    const [logo, setLogo] = React.useState(Logo)

    useEffect(() => {
        if (mode == 'dark') {
            setLogo(LogoDark)
        } else {
            setLogo(Logo)
        }
    }, [mode, logo])

    return (
        <Box className='footer' id='aboutus' sx={{ width: '100%' }}>
            <Box className='social-media-icons' backgroundColor='background.contact'>
                <Grid className='social-media-icons' container>
                    <Grid item xs={4} sm={4}>
                        <a
                            href='https://www.facebook.com/chacochii'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <IconButton aria-label='facebook' color='primary'>
                                <FacebookIcon />
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <a
                            href='https://www.youtube.com/@chacachien'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <IconButton aria-label='youtube' color='primary'>
                                <YouTubeIcon />
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <a
                            href='https://github.com/chacachiene'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <IconButton aria-label='instagram' color='primary'>
                                <GitHubIcon />
                            </IconButton>
                        </a>
                    </Grid>
                </Grid>
            </Box>

            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    flexGrow: 1,
                    // set center
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'background.footer',
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={8} md={4} container direction='column'>
                        <Grid item xs container justifyContent='right' textAlign='right'>
                            <Typography gutterBottom variant='subtitle1' component='div'>
                                About us
                            </Typography>
                            <Typography variant='body2' component='div'>
                                Nơi chúng mình, những cô cậu sinh viên chẳng còn mấy thời
                                gian trên ghế giảng đường sẻ chia những câu chuyện, cuốn
                                sách và bài học cột sống.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} container justifyContent='center'>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img alt='complex' src={logo} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} container>
                        <Grid item xs container justifyContent='left' textAlign='left'>
                            <Typography gutterBottom variant='subtitle1' component='div'>
                                Contact us
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent='left'
                            textAlign='left'
                            direction='column'
                        >
                            <Typography
                                variant='body2'
                                component='div'
                                gutterBottom
                                lineHeight={1.5}
                                sx={{ display: 'flex' }}
                            >
                                <PhoneIcon sx={{ marginRight: '5px' }} />  0923-544-575
                            </Typography>
                            <Typography
                                variant='body2'
                                gutterBottom
                                lineHeight={1.5}
                                sx={{ display: 'flex' }}
                            >
                                <EmailIcon sx={{ marginRight: '5px' }} />{' '}
                                chacachiene@gmail.com
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container justifyContent='center'>
                    <Typography component='div' variant='caption' mt='5px'>
                        Copyright © 2023 | Made by Cha Co Chi
                    </Typography>
                </Grid>
            </Paper>
        </Box>
    )
}

export default Footer
