import axios from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// interceptors

axiosClient.interceptors.request.use(async (config) => {
    // handle token here ...
    return config;
}


);
axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}
    , (error) => {
        // Handle errors
        throw error;
    }
);

export default axiosClient;